export default (rule, value, callback) => {
  try {
    new RegExp(value).test(window.location.href);
    callback();
  } catch {
    callback(new Error('Invalid RegExp'));
  }
};

export async function emailValidation(value) {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (emailRegex.test(value)) return;
  throw new Error('Invalid email address');
}
