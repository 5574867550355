<template>
  <div class="ab-variant">
    <app-loader
      key="loader"
      class="ab-variant__loader"
      :loading="(!loaded || delayedRefresh) && hasExtension"
      :loaders="[{ text: 'Loading Page' }]"
      :class="device" />
    <transition name="el-fade-in">
      <div v-if="(loaded || error) && !hasExtension" class="ab-variant__helper">
        <i class="el-icon-help" />
        <p> You need to install <a href="https://helper.jumbleberry.com/" target="_blank">Pixel Helper</a> <br> before using this editor </p>
      </div>
      <div v-else-if="error" class="ab-variant__helper">
        <i class="el-icon-warning-outline" />
        <p> Failed to load URL </p>
      </div>
      <div v-else-if="src == 'about:blank' && hasExtension" class="ab-variant__helper">
        <i class="el-icon-guide" />
        <p> Enter a URL to <br> start optimizing </p>
      </div>
    </transition>
    <iframe
      :key="frameId"
      ref="iframe"
      :src="frameSrc"
      class="ab-variant__frame"
      :class="device"
      sandbox="allow-forms allow-presentation allow-scripts allow-same-origin"
      @load="handleLoad" />
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import AppLoader from '@/components/AppLoader';

export default {
  name: 'AbVariant',
  components: { AppLoader },
  props: {
    id: {
      type: String,
      required: true
    },
    hasExtension: {
      type: Boolean,
      required: true
    },
    variant: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    sidebarDepth: {
      type: Number,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    device: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      frameId: 0,
      frameSrc: this.hasExtension ? this.src : 'about:blank',
      loaded: false,
      error: false,
      delayedRefresh: false,
      loadTimeout: undefined,
      debouncedSendActive: _debounce(this.sendActive, 25, { leading: false, trailing: true })
    };
  },
  computed: {
    modification() {
      return this.variant.activeModification >= 0
        ? this.variant.modifications[this.variant.activeModification]
        : undefined;
    },
    selector() {
      return this.modification ? this.modification.selector : undefined;
    },
    activeModificationSelector() {
      return this.variant.activeModificationSelector;
    }
  },
  watch: {
    src() {
      this.refresh();
    },
    hasExtension(init) {
      if (init && this.src !== this.frameSrc) this.refresh();
    },
    visible(visible) {
      if ((visible && this.delayedRefresh) || (!visible && !this.loaded)) this.refresh();
      if (!visible) this.sendReset();
    },
    frameId: {
      immediate: true,
      handler() {
        clearTimeout(this.loadTimeout);
        this.loadTimeout = setTimeout(() => {
          this.error = true;
          this.frameSrc = 'about:blank';
          ++this.frameId;
          this.handleLoad();
        }, 15000);
      }
    },
    sidebarDepth(depth) {
      if (depth <= 1) this.sendReset();
    },
    variant: {
      deep: true,
      handler() {
        this.sendModifications();
      }
    },
    'variant.activeModificationSelector'(active) {
      if (active) this.debouncedSendActive();
    },
    'variant.selector'(callback) {
      if (callback) this.sendMessage({ event: 'selector' });
      else this.debouncedSendActive();
    },
    'variant.teleport'(state) {
      if (state) this.sendMessage({ event: 'teleport' });
      else this.debouncedSendActive();
    },
    modification(newMod, oldMod) {
      if (newMod && !oldMod) this.debouncedSendActive();
    },
    'modification.selector'(selector) {
      if (selector) this.debouncedSendActive();
    }
  },
  created() {
    window.addEventListener('message', this.handleMessage);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage);
  },
  methods: {
    refresh() {
      if (this.visible && this.hasExtension) {
        this.frameId++;
        this.frameSrc = this.src;
        this.loaded = this.error = false;
        this.delayedRefresh = false;
        this.$emit('sdk', { id: this.id, sdk: false });
      } else {
        this.delayedRefresh = true;
      }
    },
    handleLoad() {
      this.loaded = true;
      this.sendModifications();
      this.sendActive();
      this.$emit('load', { id: this.id, src: this.frameSrc });

      clearTimeout(this.loadTimeout);
    },
    handleMessage({ source, data }) {
      try {
        if (source === this.$refs.iframe.contentWindow && data.source === 'jbac') {
          this.handleEvent(data);
        }
      } catch (err) {}
    },
    /* eslint complexity: ["error", 20] */
    handleEvent({ event, ...data }) {
      const { id } = this;

      switch (event) {
        case 'init':
          return this.$emit('init');
        case 'sdk':
          return this.$emit('sdk', { id, sdk: { version: data.version, domain: data.domain } });
        case 'snapshot':
          return this.modification && (this.modification.snapshot = data.snapshot);
        case 'teleport':
          return this.modification && (this.modification.teleport = data.teleport);
        case 'hide':
          if (this.modification) {
            this.modification.style =
              this.modification.style === ':scope { display: none !important; }'
                ? undefined
                : ':scope { display: none !important; }';
          }
          return;
        case 'selector':
          return this.variant.selector(data.selector);
        case 'undo':
          return this.$emit('undo', { id, data });
        case 'active':
          return this.$emit('active', { id, data });
        case 'finish':
          return this.$emit('finish', { id, data });
      }
    },
    sendModifications() {
      this.sendMessage({ event: 'modifications', modifications: this.variant.modifications });
    },
    sendActive() {
      this.modification &&
        this.sendMessage({
          event: 'active',
          selector: this.activeModificationSelector || this.selector,
          modification: this.modification
        });
    },
    sendReset() {
      this.sendMessage({ event: 'reset' });
    },
    sendMessage(data) {
      this.$refs.iframe.contentWindow.postMessage({ ...data, source: 'jbac' }, '*');
    }
  }
};
</script>

<style lang="scss" scoped>
.ab-variant {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__loader,
  &__helper {
    background: $--clb-color-primary__white;
    z-index: 1;
    width: 100%;
  }

  &__helper {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;

    i {
      font-size: 72px;
      margin-top: 25vh;
      margin-bottom: $--clb-space-6;
    }
  }

  &__loader,
  &__frame {
    position: absolute;
    height: 100%;
    width: 100%;
    min-width: 768px;
    max-width: 9999px;
    overflow: auto;
    margin: 0;

    &.mobile {
      min-width: 375px;
      width: 375px;
      max-width: 375px;
      border: 1px solid $--clb-border-color-base;
      border-top: none;
      border-bottom: none;
      box-shadow: 0 0 80px -10px rgba(28, 24, 7, 0.1);
    }
  }
}
</style>
