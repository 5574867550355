<template>
  <div
    v-loading="variant.selector"
    element-loading-text="Click on an Element to Capture its' CSS Selector"
    class="ab-sidebar-configuration"
    element-loading-spinner="el-icon-magic-stick">
    <el-form ref="form" :model="configuration">
      <h4>Name</h4>
      <el-form-item prop="name" :rules="validation.name">
        <el-input
          v-model="configuration.name"
          placeholder="Short name for this test"
          size="mini" />
      </el-form-item>
      <h4>Description</h4>
      <el-form-item prop="desc" :rules="validation.desc">
        <el-input
          v-model="configuration.desc"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          placeholder="Describe this test"
          size="mini" />
      </el-form-item>
      <h4>Trigger</h4>
      <el-form-item prop="triggers[0].url" :rules="validation.url">
        <el-input
          v-model="configuration.triggers[0].url"
          placeholder="URL Regex"
          size="mini" />
      </el-form-item>
      <el-form-item prop="triggers[0].selector" :rules="validation.selector">
        <el-input v-model="configuration.triggers[0].selector" placeholder="CSS Selector" size="mini">
          <template slot="append">
            <el-button icon="el-icon-magic-stick" size="small" :disabled="!hasInitialized" @click="handleGetSelector" />
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <h4>Devices</h4>
    <dropdown-filter
      v-model="configuration.devices"
      :data="devices"
      :clearable="true"
      :remote="false"
      :multiple="true"
      :append-to-body="true"
      :collapse-tags="collapseTags" />
    <h4>Campaigns</h4>
    <dropdown-filter
      v-model="configuration.campaigns"
      :multiple="true"
      :loading="isLoadingCampaigns"
      :remote-method="getCampaigns"
      :data="campaigns" />
    <h4>Status</h4>
    <el-radio-group v-model="configuration.status" size="small" class="ab-sidebar-configuration__device">
      <el-radio-button label="pending">
        Pending
      </el-radio-button>
      <el-radio-button label="running">
        Running
      </el-radio-button>
      <el-radio-button label="cancelled">
        Cancelled
      </el-radio-button>
    </el-radio-group>
    <h4 class="ab-sidebar-configuration__variant">
      Variants <el-button
        v-if="!hasStarted"
        size="mini"
        type="primary"
        class="ab-sidebar-configuration__variant-add"
        @click="$emit('add')">
        Add <i class="el-icon-circle-plus-outline" />
      </el-button>
    </h4>
    <transition-group name="el-fade-in" tag="ul">
      <li v-for="v in variants" :key="v.id" @click="$emit('edit', v.id)">
        {{ v.name }} <i class="el-icon-arrow-right" />
      </li>
    </transition-group>
    <app-button @click="handleSave">
      Save Test <i class="el-icon-arrow-right" />
    </app-button>
    <a class="ab-sidebar-configuration__delete" @click="$emit('cancel')">Cancel & View Tests</a>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton';
import DropdownFilter from '@/components/filters/DropdownFilter';
import RegexValidator from '@/validators/regex';
import SelectorValidator from '@/validators/selector';

export default {
  name: 'SidebarConfiguration',
  components: {
    AppButton,
    DropdownFilter
  },
  props: {
    hasInitialized: {
      type: Boolean,
      required: true
    },
    hasStarted: {
      type: Boolean,
      required: true
    },
    configuration: {
      type: Object,
      required: true
    },
    variants: {
      type: Array,
      required: true
    },
    variant: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      devices: ['desktop', 'tablet', 'mobile'],
      isLoadingCampaigns: false,
      campaigns: [],
      validation: {
        name: [{ required: true, message: 'Name is required' }],
        desc: [{ required: true, message: 'Description is required' }],
        url: [
          {
            required: true,
            trigger: 'change',
            message: 'URL RegExp is required'
          },
          {
            validator: RegexValidator,
            trigger: 'change'
          }
        ],
        selector: [
          {
            required: true,
            trigger: 'change',
            message: 'CSS Selector is required'
          },
          {
            validator: SelectorValidator,
            trigger: 'change'
          }
        ]
      }
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organizationId;
    },
    collapseTags() {
      return this.configuration.devices.length >= 3;
    }
  },
  mounted() {
    this.getCampaigns();
  },
  beforeDestroy() {
    if (this.variant) this.variant.selector = undefined;
  },
  methods: {
    getCampaigns(searchString = '') {
      this.isLoadingCampaigns = true;
      this.$api.campaigns
        .all({
          orgId: this.orgId,
          q: searchString,
          options: {
            page: 1
          },
          cancellationKey: `campaigns-${this.orgId}-${searchString}`
        })
        .then(result => {
          this.campaigns = result.data;
        })
        .finally(() => {
          this.isLoadingCampaigns = false;
        });
    },
    handleGetSelector() {
      if (!this.hasInitialized) return;

      if (!this.variant.selector) {
        this.variant.selector = result => {
          this.configuration.triggers[0].selector = result;
          this.variant.selector = undefined;
        };
      } else {
        this.variant.selector = undefined;
      }
    },
    handleSave() {
      this.$refs.form.validate((valid, errors) => {
        valid
          ? this.$emit('save')
          : this.$message({
            message: 'Configuration has validation errors',
            type: 'error'
          });
      });
    }
  }
};
</script>

<style lang="scss">
.ab-sidebar-configuration {
  &__variant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $--clb-space-1;

    &-add i {
      padding-left: $--clb-space-1;
    }
  }
}
</style>
