<template>
  <div class="ab-sidebar-variant">
    <h4>Name</h4>
    <el-input
      v-model="variant.name"
      placeholder="Name"
      size="mini"
      :disabled="variant.id == '1'" />
    <h4>Weight</h4>
    <el-input-number
      v-model="variant.weight"
      :min="0"
      :max="1000"
      :step="10"
      controls-position="right"
      size="small" />
    <h4 class="ab-sidebar-variant__modification">
      Modifications <el-button
        size="mini"
        type="primary"
        class="ab-sidebar-configuration__variant-add"
        @click="$emit('add')">
        Add <i class="el-icon-circle-plus-outline" />
      </el-button>
    </h4>
    <draggable v-model="variant.modifications" v-bind="{ delay: 25, forceFallback: true }">
      <transition-group
        v-if="modifications.length"
        name="el-fade-in"
        tag="ul"
        class="ab-sidebar-variant__modifications">
        <li v-for="(modification, i) in modifications" :key="modification.id" @click="$emit('edit', i)">
          <span><i class="el-icon-d-caret" /> {{ modification.selector || `Modification ${modification.id}` }}</span><i class="el-icon-arrow-right" />
        </li>
      </transition-group>
      <ul v-else class="ab-sidebar-variant__modifications empty">
        <li> No Modifications </li>
      </ul>
    </draggable>
    <app-button @click="$emit('back')">
      Save Variant <i class="el-icon-arrow-right" />
    </app-button>
    <a v-if="variant.id !== '1' && !hasStarted" class="ab-sidebar-variant__delete" @click="$emit('delete')">Delete Variant</a>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton';
import Draggable from 'vuedraggable';

export default {
  name: 'SidebarVariant',
  components: { AppButton, Draggable },
  props: {
    hasInitialized: {
      type: Boolean,
      required: true
    },
    hasStarted: {
      type: Boolean,
      required: true
    },
    variant: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      name: undefined,
      weight: 50
    };
  },
  computed: {
    modifications() {
      return this.variant.modifications;
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.ab-sidebar-variant {
  .sortable-drag,
  .sortable-chosen,
  .el-icon-d-caret {
    cursor: grab !important;
  }

  .el-icon-d-caret {
    margin: 0 $--clb-space-1 !important;
  }

  &__modification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $--clb-space-1;

    &-add i {
      padding-left: $--clb-space-1;
    }
  }

  &__modifications {
    user-select: none;

    .el-fade-in-move {
      transition: transform 0.6s;
    }

    li,
    span {
      display: flex;
      align-items: center;
      background: $--clb-color-primary__white;
      overflow: hidden;
    }

    &.empty li:hover {
      cursor: not-allowed !important;
    }
  }
}
</style>
