<template>
  <div
    v-loading="variant.selector || variant.teleport"
    element-loading-text="Click on an Element to Capture its' CSS Selector"
    class="ab-sidebar-modification"
    element-loading-spinner="el-icon-magic-stick">
    <h4>Selector</h4>
    <el-input
      v-model="modification.selector"
      placeholder="CSS Selector"
      size="mini">
      <template slot="append">
        <el-button icon="el-icon-magic-stick" size="small" :disabled="!hasInitialized" @click="handleGetSelector" />
      </template>
    </el-input>
    <el-tabs
      ref="tabs"
      v-model="modificationType"
      stretch
      class="ab-sidebar-modification__types">
      <el-tab-pane
        v-for="item in modificationTabs"
        :key="item.name"
        :label="item.label"
        :name="item.name">
        <el-select
          v-if="modificationType === 'html'"
          v-model="htmlProperty"
          class="ab-sidebar-modification__types-property"
          placeholder="Select"
          size="mini">
          <el-option
            v-for="property in htmlProperties"
            :key="property"
            :label="property"
            :value="property" />
        </el-select>
        <CodeMirror
          :key="mirrorId"
          :value="editorValue"
          :options="{ ...editorOptions, mode: item.mode, readOnly }"
          @focus="activateInput"
          @input="handleInput" />
      </el-tab-pane>
    </el-tabs>
    <transition name="el-fade-in">
      <a v-if="hasContent" class="ab-sidebar-modification__types-reset" @click="resetContent">Reset</a>
    </transition>
    <h4>Teleport</h4>
    <el-input v-model="teleport.selector" placeholder="Selector" size="mini">
      <template slot="append">
        <el-button icon="el-icon-magic-stick" size="small" :disabled="!hasInitialized" @click="handleGetTeleport" />
      </template>
    </el-input>
    <transition name="el-fade-in">
      <el-select
        v-if="teleport.selector"
        v-model="teleport.property"
        class="ab-sidebar-modification__teleport-property"
        placeholder="Select"
        size="mini">
        <el-option
          v-for="property in teleportLocations"
          :key="property"
          :label="property"
          :value="property" />
      </el-select>
    </transition>
    <app-button @click="$emit('back')">
      Save Modification <i class="el-icon-arrow-right" />
    </app-button>
    <a class="ab-sidebar-modification__delete" @click="$emit('delete')">Delete Modification</a>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton';
import CodeMirror from '@/components/AppCodeMirror';
import { html } from 'js-beautify';

export default {
  name: 'SidebarModification',
  components: {
    AppButton,
    CodeMirror
  },
  props: {
    hasInitialized: {
      type: Boolean,
      required: true
    },
    hasStarted: {
      type: Boolean,
      required: true
    },
    variant: {
      type: Object,
      required: true
    },
    modification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modificationType: 'html',
      modificationTabs: [
        { label: 'HTML', name: 'html', mode: 'text/html' },
        { label: 'Script', name: 'script', mode: 'application/javascript' },
        { label: 'Style', name: 'style', mode: 'text/css' }
      ],
      htmlProperties: ['outerHTML', 'innerHTML', 'innerText'],
      htmlProperty: (this.modification.html && this.modification.html.property) || 'outerHTML',
      teleportLocations: [
        'firstChild',
        'lastChild',
        'nextSibling',
        'nextElementSibling',
        'previousSibling',
        'previousElementSibling'
      ],
      mirrorId: 0,
      editorOptions: Object.freeze({
        mode: 'text/html',
        theme: 'jumbleberry-light',
        tabSize: 2,
        viewportMargin: 16,
        lineNumbers: false,
        line: true,
        lineWrapping: true
      })
    };
  },
  computed: {
    isEditorHtml() {
      return this.modificationType === 'html';
    },
    readOnly() {
      return this.isEditorHtml && (!this.modification.html || this.modification.html.property !== this.htmlProperty);
    },
    editorValue() {
      if (this.isEditorHtml) {
        return this.readOnly ? this.formattedSnapshot[this.htmlProperty] : this.modification.html.code;
      }

      return this.modification[this.modificationType];
    },
    hasContent() {
      return (this.isEditorHtml && !this.readOnly) || (!this.isEditorHtml && this.modification[this.modificationType]);
    },
    teleport() {
      return (this.modification && this.modification.teleport) || {};
    },
    snapshot() {
      return (this.modification && this.modification.snapshot) || {};
    },
    formattedSnapshot() {
      const formattedSnapshot = Object.assign({}, this.snapshot);
      this.htmlProperties.forEach(p => (formattedSnapshot[p] = html(formattedSnapshot[p] || '', { indent_size: 2 })));
      return formattedSnapshot;
    }
  },
  watch: {
    '$store.getters.leftSidebarWidth'() {
      let steps = 300 / 20;
      const interval = setInterval(() => {
        if (this.$refs.tabs) this.$refs.tabs.calcPaneInstances(true);
        if (--steps <= 0 && ++this.mirrorId) clearInterval(interval);
      }, 20);
    },
    htmlProperty() {
      this.resetContent();
    },
    teleport() {
      this.variant.teleport = undefined;
    },
    'teleport.selector'(selector) {
      if (this.modification.teleport && !selector) this.modification.teleport = undefined;
    }
  },
  beforeDestroy() {
    this.variant.selector = undefined;
    this.variant.teleport = undefined;
  },
  methods: {
    handleGetSelector() {
      if (!this.hasInitialized) return;

      if (!this.variant.selector) {
        this.variant.selector = result => {
          this.modification.selector = result;
          this.variant.selector = undefined;
        };
      } else {
        this.variant.selector = undefined;
      }
    },
    handleGetTeleport() {
      if (!this.hasInitialized) return;

      this.variant.teleport = !this.variant.teleport;
    },
    resetContent() {
      this.modification[this.modificationType] = undefined;
    },
    activateInput() {
      if (this.isEditorHtml) {
        this.modification.html = { property: this.htmlProperty, code: this.editorValue };
      }
    },
    handleInput(code) {
      if (this.isEditorHtml && !this.readOnly) {
        this.modification[this.modificationType] = { property: this.htmlProperty, code };
      } else if (!this.isEditorHtml) {
        this.modification[this.modificationType] = code;
      }
    }
  }
};
</script>

<style lang="scss">
.ab-sidebar-modification {
  &__types {
    margin-top: $--clb-layout-1;

    &-property {
      margin-bottom: $--clb-space-3;
    }

    &-reset {
      font-size: $--clb-font-size-xs;
      font-weight: 600;
      padding: $--clb-space-1 0;
      float: right;
      margin-top: 0 !important;
      margin-bottom: -20px !important;
    }
  }

  .app-code-mirror {
    min-height: 322px;

    .CodeMirror-scroll {
      max-height: 480px;
      min-height: 320px;
    }

    &.read-only {
      opacity: 0.5;

      &,
      .CodeMirror-scroll,
      .CodeMirror-lines {
        cursor: pointer;
      }
    }
  }
}
</style>
