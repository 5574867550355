<template>
  <div class="ab-integration-wrapper">
    <div v-if="isMobile" class="ab-integration-mobile tw-p-layout-2 tw-flex tw-flex-col tw-h-full tw-bg-white">
      <app-link weight="semi-bold" size="sm" class="!tw-text-jb-indigo" @click="$router.push({ name: 'ab' })">
        <i class="el-icon-arrow-left tw-font-black" />
        Back to List
      </app-link>
      <ab-testing-illustration class="ab-testing-illustration tw-mt-layout-3 xs:tw-w-52 tw-w-40 tw-mx-auto" />
      <app-heading level="h4" weight="semi-bold" class="tw-text-jb-ink tw-mt-layout-2 tw-mb-layout-1 tw-text-center">
        AB Testing
      </app-heading>
      <app-text type="p" size="sm" class="tw-text-jb-grey-700 tw-w-64 tw-max-w-full tw-mx-auto tw-text-center tw-break-normal" :style="{ hyphens: 'manual' }">
        For optimal functionality, please switch to a bigger screen size
      </app-text>
    </div>

    <div v-else class="ab-integration tw-border-jb-grey-50 tw-h-full tw-border-l tw-border-solid">
      <portal to="app-right-sidebar-extension">
        <div key="app-right-sidebar-extension--ab-integration" v-loading="fetchingTest" class="ab-integration__sidebar tw-py-space-3 tw-px-space-3 tw-pb-space-4 tw-max-h-full tw-overflow-auto tw-bg-white">
          <div class="ab-integration__sidebar-header">
            <transition name="el-fade-in" mode="out-in">
              <div v-if="sidebarDepth > 0" class="ab-integration__sidebar-header--location" @click="handleSidebarDepth">
                <i class="el-icon-arrow-left" /> {{ sidebarLabels[sidebarDepth - 1] }}
              </div>
            </transition>
            <div
              class="ab-integration__sidebar-header--size"
              @click="handleSizebarWidth">
              <i :class="sidebarWidthIcon" />
              <transition name="el-fade-in" mode="out-in">
                <span :key="sidebarWidthText">{{ sidebarWidthText }}</span>
              </transition>
            </div>
          </div>
          <transition :name="sidebarTransition" mode="out-in">
            <ab-sidebar-modification
              v-if="sidebarDepth === 2 && modification"
              :key="`${activeVariant}-${variant.activeModification}`"
              :has-initialized="hasExtension && src !== 'about:blank'"
              :has-started="hasStarted"
              :variant="variant"
              :modification="modification"
              @back="handleSidebarDepth"
              @delete="handleDeleteModification" />
            <ab-sidebar-variant
              v-else-if="sidebarDepth >= 1"
              :key="activeVariant"
              :has-initialized="hasExtension && src !== 'about:blank'"
              :has-started="hasStarted"
              :variant="variant"
              @back="handleSidebarDepth"
              @add="handleAddModification"
              @edit="handleEditModification"
              @delete="handleDeleteVariant" />
            <ab-sidebar-configuration
              v-else
              :has-initialized="hasExtension && src !== 'about:blank'"
              :has-started="hasStarted"
              :configuration="configuration"
              :variants="variants"
              :variant="variant"
              @add="handleAddVariant"
              @edit="handleEditVariant"
              @save="handleSave"
              @cancel="handleCancel" />
          </transition>
        </div>
      </portal>

      <portal to="app-right-sidebar-extension-handler">
        <app-sidebar-handle key="app-right-sidebar-extension-handler--ab-integration" slot-scope="props" @click="props.click">
          AB Testing
        </app-sidebar-handle>
      </portal>

      <div class="ab-integration__header">
        <el-button-group class="ab-integration__header-controls">
          <el-button
            :disabled="historyIndex <= 0"
            icon="el-icon-back"
            size="small"
            @click="historyIndex -= 1" />
          <el-button
            :disabled="historyIndex >= history.length - 1"
            icon="el-icon-right"
            size="small"
            @click="historyIndex += 1" />
          <el-button
            :disabled="historyIndex < 0"
            icon="el-icon-refresh-right"
            size="small"
            @click="handleRefresh" />
        </el-button-group>
        <el-badge :value="(!sdkDetected ? 'No ': '') + 'Pixel Detected'" :type="sdkDetected ? 'success' : 'danger'" class="ab-integration__header-searchbar">
          <input
            :value="src"
            class="ab-integration__header-searchbar--url"
            @blur="srcHasFocus = false"
            @click="handleUrlClick"
            @keyup="handleUrlKeyup">
        </el-badge>
        <el-radio-group v-model="device" size="small" class="ab-integration__header-device">
          <el-radio-button label="desktop" size="small">
            <i class="el-icon-monitor" />
          </el-radio-button>
          <el-radio-button label="mobile" size="small">
            <i class="el-icon-mobile" />
          </el-radio-button>
        </el-radio-group>
      </div>
      <el-tabs
        v-model="activeVariant"
        type="card"
        class="ab-integration__tabs"
        :editable="!hasStarted"
        @edit="handleTabsEdit"
        @tab-click="handleTabClick">
        <el-tab-pane
          v-for="tab in variants"
          :key="tab.id"
          :label="tab.name"
          :name="tab.id">
          <ab-variant
            v-if="!fetchingTest"
            :id="tab.id"
            :key="tab.id"
            ref="variant"
            :has-extension="hasExtension"
            :variant="tab"
            :visible="tab.id === activeVariant"
            :sidebar-depth="sidebarDepth"
            :src="history[historyIndex] || ''"
            :device="device"
            @init="handleInit"
            @load="handleLoad"
            @active="handleActive"
            @finish="handleFinish"
            @undo="handleUndo"
            @sdk="handleSdk" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import AppText from '@/components/AppText.vue';
import AppLink from '@/components/AppLink.vue';
import AppHeading from '@/components/AppHeading.vue';
import AppSidebarHandle from '@/components/AppSidebarHandle.vue';
import AbVariant from '@/components/ab/Variant';
import AbSidebarConfiguration from '@/components/ab/SidebarConfiguration';
import AbSidebarVariant from '@/components/ab/SidebarVariant';
import AbSidebarModification from '@/components/ab/SidebarModification';
import AbTestingIllustration from '@/assets/svg/ab-testing-illustration.svg';
import Breakpoints from '@/mixins/Breakpoints';

const createVariant = function(id, name) {
  return {
    id: `${id}`,
    variant_id: undefined,
    sdk: false,
    name,
    weight: 50,
    modificationId: 1,
    modifications: [],
    activeModification: undefined,
    activeModificationSelector: undefined,
    selector: undefined,
    teleport: undefined
  };
};

const createModification = (modification = {}, modificationId) => {
  const modificationClone = {};
  ['selector', 'style', 'script', 'html', 'teleport', 'snapshot'].forEach(
    p => (modificationClone[p] = modification[p] || undefined)
  );
  modificationClone.id = modification.id || modificationId;
  modificationClone.temporary = undefined;
  return modificationClone;
};

export default {
  name: 'Integration',
  components: {
    AppText,
    AppLink,
    AppHeading,
    AppSidebarHandle,
    AbVariant,
    AbSidebarConfiguration,
    AbSidebarVariant,
    AbSidebarModification,
    AbTestingIllustration
  },
  mixins: [Breakpoints([992])],
  props: {},
  data() {
    return {
      hasExtension: false,
      srcHasFocus: false,
      historyIndex: 0,
      device: 'desktop',
      sidebarDepth: 0,
      sidebarLabels: ['Setup', 'Variant', 'Modification'],
      sidebarTransition: 'slide-left',
      sidebarExpanded: false,
      fetchingTest: false,
      savingTest: false,
      variantId: 1,
      activeVariant: '1',
      configuration: {
        id: undefined,
        name: '',
        desc: '',
        history: ['about:blank'],
        triggers: [
          {
            url: undefined,
            selector: undefined
          }
        ],
        devices: ['desktop', 'tablet', 'mobile'],
        campaigns: [],
        status: 'pending',
        variants: [createVariant(1, 'Baseline')]
      }
    };
  },

  computed: {
    test() {
      return {
        ...this.configuration,
        variants: this.variants
      };
    },
    history() {
      return this.configuration.history;
    },
    src() {
      return this.history[this.historyIndex] || '';
    },
    activeVariantRef() {
      return this.$refs.variant.find(v => v.visible);
    },
    sidebarWidthText() {
      return this.sidebarExpanded ? 'Shrink' : 'Expand';
    },
    sidebarWidthIcon() {
      return this.sidebarExpanded ? 'el-icon-arrow-right' : 'el-icon-arrow-left';
    },
    variants() {
      return this.configuration.variants || [];
    },
    variant() {
      return this.variants.find(v => v.id === this.activeVariant);
    },
    sdkDetected() {
      return this.variant.sdk !== false;
    },
    modification() {
      return this.variant.activeModification !== undefined
        ? this.variant.modifications[this.variant.activeModification]
        : undefined;
    },
    isModificationTemporary() {
      const { id, selector, snapshot, ...remaining } = this.modification || {};
      return this.modification && remaining && Object.values(remaining).filter(v => !!v).length < 1;
    },
    hasStarted() {
      return this.variants.some((v) => parseInt(v.participants) > 0);
    },
    isMobile() {
      return this.windowBreakpoint < 992;
    }
  },

  watch: {
    '$store.getters.leftSidebarWidth'(width) {
      if (!width) this.sidebarExpanded = false;
    },
    activeVariant(newVariant, oldVariant) {
      // User maintained depth but switched between variants, fade between panels
      if (this.sidebarDepth === 1) {
        this.sidebarTransition = 'el-fade-in';
      }

      // Variant was changed, so the previous variant should have no active selection
      if (oldVariant >= 0 && this.variants[oldVariant]) {
        this.variants[oldVariant].activeModificationSelector = undefined;
      }
    },
    modification(newMod, oldMod) {
      // User maintained depth but switched modifications, fade between panels
      if (this.sidebarDepth === 2 && oldMod && newMod) {
        this.sidebarTransition = 'el-fade-in';

        // No active modification so no active selection
      } else if (!newMod && oldMod) {
        this.variant.activeModificationSelector = undefined;
      }
    },
    sidebarDepth(newVal, oldVal) {
      this.sidebarTransition = newVal > oldVal ? 'slide-left' : 'slide-right';

      // If user navigatess away from modifications, then ensure no modification is active
      if (newVal < 2) this.variant.activeModification = this.variant.activeModificationSelector = undefined;
    }
  },

  created() {
    if (parseInt(this.$route.params.id) > 0) {
      this.fetchingTest = true;
      this.$store
        .dispatch('fetchAbTest', {
          orgId: this.$store.getters.organization.id,
          testId: this.$route.params.id
        })
        .then(test => {
          // Convert the API response into our structure
          test.variants = test.variants.map((v, i) => {
            v.modifications = v.modifications.map((m, j) => createModification(m, j + 1));
            return Object.assign(createVariant(i + 1, v.name), v);
          });

          this.configuration = test;
          this.historyIndex = this.configuration.history.length - 1;
          this.variantId = this.configuration.variants.length;
        })
        .catch(() => {
          this.$message({
            message: 'Unable to load test details.',
            type: 'error'
          });
          this.handleCancel();
        })
        .finally(() => {
          this.fetchingTest = false;
        });
    }
  },

  destroyed() {
    this.$store.dispatch('setRightDrawerWidth', undefined);
    this.$store.dispatch('setLeftSidebarWidth', undefined);
  },

  methods: {
    handleTabClick({ name }) {
      this.sidebarDepth = 1;
    },
    handleTabsEdit(targetId, action) {
      if (action === 'add') {
        this.activeVariant = `${++this.variantId}`;
        this.variants.push(createVariant(this.activeVariant, `Variant ${this.activeVariant}`));
        this.sidebarDepth = 1;
      } else if (action === 'remove') {
        const index = this.variants.findIndex(t => t.id === targetId);

        // Select a new active tab is the current one is getting removed
        if (this.activeVariant === targetId) {
          const nextVariant = this.variants[index + 1] || this.variants[index - 1] || this.variants[0];
          this.activeVariant = nextVariant.id;
        }

        this.variants.splice(index, 1);
      }
    },

    handleSizebarWidth() {
      this.sidebarExpanded = !this.sidebarExpanded;
      this.$store.dispatch('setRightDrawerWidth', this.sidebarExpanded ? 650 : undefined);
      this.$store.dispatch('setLeftSidebarWidth', this.sidebarExpanded ? 0.1 : undefined);
    },
    handleRefresh() {
      this.activeVariantRef.refresh();
    },
    handleUrlClick(ev) {
      if (!this.srcHasFocus) document.execCommand('selectall', null, false);
      this.srcHasFocus = true;
    },
    handleUrlKeyup({ key, target }) {
      if (key === 'Enter') {
        const hasHttp = target.value.indexOf('http') === 0;
        this.handleNavigation(`${!hasHttp ? 'https://' : ''}${target.value}`);
      }
    },
    handleNavigation(src) {
      if (src && this.history[this.historyIndex] !== src) {
        this.history.splice(this.historyIndex + (this.src === 'about:blank' ? 0 : 1), this.history.length, src);
        this.historyIndex = this.history.length - 1;
      }
    },

    handleSidebarDepth() {
      if (this.sidebarDepth === 2 && this.isModificationTemporary) {
        return this.handleDeleteModification();
      }

      this.sidebarDepth = Math.max(0, --this.sidebarDepth);
    },

    handleAddVariant() {
      this.handleTabsEdit(null, 'add');
    },
    handleEditVariant(variantId) {
      this.sidebarDepth = 1;
      this.activeVariant = variantId;
    },
    handleDeleteVariant() {
      this.handleTabsEdit(this.activeVariant, 'remove');
      this.sidebarDepth = 0;
    },

    handleAddModification() {
      this.variant.modifications.push(createModification({}, ++this.variant.modificationId));
      this.variant.activeModification = this.variant.modifications.length - 1;
      this.sidebarDepth = 2;
    },
    handleEditModification(modificationIndex) {
      this.sidebarDepth = 2;
      this.variant.activeModification = modificationIndex;
    },
    handleDeleteModification() {
      this.sidebarDepth = 1;
      this.modification && this.variant.modifications.splice(this.variant.activeModification, 1);
      this.variant.activeModification = this.variant.activeModificationSelector = undefined;
    },

    handleInit() {
      this.hasExtension = true;
    },
    handleLoad({ id, src }) {
      if (src !== 'about:blank') this.$set(this.history, this.historyIndex, src);
    },
    handleFinish({ id, data }) {
      if (this.sidebarDepth > 0) {
        this.handleSidebarDepth();
      }
    },
    handleActive({ id, data }) {
      if (data.selector === this.variant.activeModificationSelector) return;
      else if (this.isModificationTemporary) this.handleDeleteModification();

      this.$nextTick(() => {
        const modification = createModification(data.modification);
        const index = this.variant.modifications.findIndex(m => m.id === modification.id);

        index === -1
          ? this.variant.modifications.push(modification)
          : Object.assign(this.variant.modifications[index], modification);

        this.sidebarDepth = 2;
        this.variant.activeModificationSelector = data.selector;
        this.variant.activeModification = this.variant.modifications.findIndex(m => m.id === modification.id);
      });
    },
    handleUndo({ id, data }) {
      Object.assign(
        this.modification,
        createModification({ id: this.modification.id, selector: this.modification.selector })
      );
    },
    handleSdk({ id, sdk }) {
      this.variants.find(v => v.id === id).sdk = sdk;
    },

    handleSave() {
      if (this.savingTest) return;

      this.savingTest = true;
      this.$store
        .dispatch('saveAbTest', {
          orgId: this.$store.getters.organization.id,
          testId: this.test.id,
          test: this.test
        })
        .then(response => {
          this.configuration.id = response.id;
          this.variants.forEach((v, i) => {
            v.variant_id = response.variants[i].variant_id;
          });

          this.$message({
            dangerouslyUseHTMLString: true,
            message: `Test <strong>${this.test.name}</strong> Saved`,
            type: 'success'
          });
        })
        .catch(e => {
          this.$message({
            message: 'There was an error saving the test.',
            type: 'error'
          });
        })
        .finally(() => {
          this.savingTest = false;
        });
    },
    handleCancel() {
      this.$router.replace({ name: 'ab' });
    }
  }
};
</script>

<style lang="scss">
.ab-integration {
  background: $--clb-color-primary__white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: calc(100 * var(--vh, 1vh) - #{$--clb-app-header-height});
  min-width: 420px;

  > * {
    display: flex;
  }

  &__header {
    padding: $--clb-space-2;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    background: $--clb-color-secondary__light;

    &-searchbar {
      height: 36px;
      flex-grow: 100;
      margin: 0 $--clb-space-2;

      .el-badge__content.is-fixed {
        top: 9px;
        right: $--clb-space-2;
        border-radius: $--clb-border-radius;
        transform: none;
        user-select: none;

        &.el-badge__content--success {
          background: #81cf74;
        }
      }

      &--url {
        border: 1px solid $--clb-border-color-base;
        border-radius: $--clb-border-radius;
        padding: $--clb-space-1 $--clb-space-2;
        font-size: $--clb-font-size-sm;
        outline: transparent;
        width: 100%;
        height: 100%;
      }
    }

    &-device {
      .el-button {
        &:focus {
          border-color: $--clb-border-color-base;
        }

        &:hover {
          border-color: $--jb-dark-primary-color;
        }
      }
    }

    .el-button:hover {
      transform: none;
      box-shadow: inherit;
    }
  }

  &__tabs {
    flex-direction: column;
    flex-grow: 1;

    > .el-tabs__header {
      margin-bottom: 0;
      background: $--clb-color-secondary__light;
      padding: 0 $--clb-space-2;

      .el-tabs__nav {
        border: none !important;
      }
    }

    .el-tabs__content,
    .el-tab-pane {
      display: flex;
      flex-grow: 1;
    }

    .el-tab-pane {
      display: flex;
      justify-content: center;
    }

    .el-tabs__item {
      padding: 0 $--clb-space-5 !important;
      margin: 0 $--clb-space-1 0 0;
      background: $--clb-skeleton-color;
      color: #bababa;
      border: 1px solid $--clb-border-color-base;
      border-bottom: none;
      border-radius: $--clb-border-radius $--clb-border-radius 0 0;

      &.is-active {
        background: #fff;
      }

      &:first-child {
        border-left: 1px solid $--clb-border-color-base !important;

        .el-icon-close {
          display: none;
        }
      }
    }
  }
}

.app-right-sidebar-extension .ab-integration__sidebar {
  h4 {
    margin: $--clb-layout-2 0 $--clb-space-2 0;
    font-size: 18px;
    color: $--clb-color__headings;
    font-weight: 700;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    line-height: $--clb-font-size-base;
    font-size: $--clb-font-size-sm;

    &--location {
      flex: 1 1 0;
      white-space: nowrap;

      i {
        display: inline-block;
        padding-right: $--clb-space-1;
        color: $--clb-border-color-dark;
      }

      &:hover {
        cursor: pointer;
        color: $--clb-body-font;

        i {
          color: $--clb-body-font;
        }
      }
    }

    &--size {
      flex: 0 1 0;
      cursor: pointer;
      min-width: 80px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      top: $--clb-mobile-padding;
      right: $--clb-mobile-padding;
      color: $--clb-border-color-dark;
      letter-spacing: 0.4px;
      transition: color $--clb-transition-time ease;

      span {
        color: transparent;
        display: inline-block;
        padding-right: $--clb-space-1;
        transform: translateX(10px);
        transition: $--clb-transition-time ease;
      }

      &:hover {
        color: $--clb-body-font;

        span {
          color: $--clb-body-font;
          transform: translateX(0);
        }
      }
    }
  }

  ul {
    li {
      padding: $--clb-space-4 0;
      border-bottom: 1px solid $--clb-border-color-base;
      display: flex;
      justify-content: space-between;
      font-size: $--clb-font-size-sm;

      i {
        color: $--clb-border-color-base;
        margin: 0 $--clb-space-2;
      }

      &:first-child {
        border-top: 1px solid $--clb-border-color-base;
      }

      &:hover {
        cursor: pointer;
        color: $--clb-color__headings;

        i {
          color: $--clb-color__headings;
        }
      }
    }
  }

  .app-button {
    width: 100%;
    margin: $--clb-layout-4 0 $--clb-space-2;
  }

  a {
    display: flex;
    justify-content: center;
    font-size: $--clb-font-size-xs;
    font-weight: 600;
    margin-top: $--clb-space-3;
  }

  .clear {
    display: inline-block;
    width: 100%;
    font-size: $--clb-font-size-xs;
    text-align: center;
  }

  .el-loading-spinner {
    i {
      font-size: 60px;
      margin-bottom: $--clb-layout-2;
    }

    .el-loading-text {
      max-width: 190px;
      margin: auto;
      font-weight: bold;
      color: $--clb-color__headings;
      font-size: $--clb-font-size-base;
    }
  }

  .el-form-item {
    margin-bottom: $--clb-space-4;
  }

  .el-input + .el-input,
  .el-input + .el-select {
    margin-top: $--clb-space-3;
  }

  .el-input--mini .el-textarea__inner,
  .el-input--mini .el-input__inner {
    padding: $--clb-space-1 $--clb-space-2 !important;
    font-size: $--clb-font-size-base !important;
  }

  .el-input-number,
  .el-input__inner {
    font-size: $--clb-font-size-base;
    min-height: 40px !important;
    width: 100%;
  }

  .el-input-group__append button.el-button {
    height: 40px;

    &:hover,
    &:active,
    &:focus {
      transform: none;
    }
  }

  .el-radio-group {
    display: flex;

    label {
      width: 100%;
    }

    .el-radio-button__inner {
      height: 40px;
      line-height: 40px;
      padding: 0 8px !important;
      width: 100%;
    }
  }
}
</style>
